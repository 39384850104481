<template>
  <v-card v-bind="$attrs" class="ticket-show">
    <v-card-title class="d-flex align-center justify-space-between">
      <strong class="fs-15 primary--text">{{ ticket.slug }}</strong>
      <div>
        <v-menu offset-y bottom left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              outlined
              color="primary"
              class="text-none mr-1"
              depressed
              v-if="can_handle_tickets"
              v-bind="attrs"
              v-on="on"
            >
              {{
                ticket.assigned[0]
                  ? 'Assigned: ' + ticket.assigned[0].first_name
                  : 'Assign To' | ucwords
              }}
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item
              @click="handleAssignTo(dev)"
              v-for="(dev, index) in developers"
              :key="index"
            >
              <v-list-item-avatar size="30" class="mr-2">
                <v-img
                  contain
                  :src="dev.image_url"
                  :lazy-src="settings.loader"
                ></v-img>
              </v-list-item-avatar>
              <v-list-item-title class="primary--text">
                {{ dev.fullname }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-menu offset-y bottom left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              outlined
              color="primary"
              class="text-none"
              depressed
              :disabled="!can_handle_tickets"
              v-bind="attrs"
              v-on="on"
            >
              Status: {{ ticket.status | ucwords }}
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item
              @click="handleChangeStatus(stat)"
              v-for="(stat, index) in can_statuses"
              :key="index"
            >
              <v-list-item-title>{{ stat }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-tooltip color="primary" top v-if="can_handle_tickets">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              @click="handleDeleteTicket"
              class="primary ml-1 light--text rounded-5"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
          <span>Delete</span>
        </v-tooltip>
      </div>
    </v-card-title>
    <v-card-text>
      <v-row align="start" class="flex-nowrap" justify="start" dense>
        <v-tooltip color="primary" top>
          <template v-slot:activator="{ on, attrs }">
            <div
              class="d-flex align-center justify-center flex-column"
              v-bind="attrs"
              v-on="on"
            >
              <v-avatar size="40" class="mr-1">
                <v-img
                  contain
                  :src="ticket.creator.image_url"
                  :lazy-src="settings.loader"
                ></v-img>
              </v-avatar>
              <strong class="primary--text fw-700">
                @{{ ticket.creator.username }}
              </strong>
            </div>
          </template>
          <strong> @{{ ticket.creator.username }} </strong>
        </v-tooltip>
        <v-col>
          <v-card flat class="arrow-left ml-2 border-1 border-color--primary">
            <v-card-title class="primary--text fw-500 fs-18">
              {{ ticket.title | ucwords }}
            </v-card-title>
            <Editor
              class="fullwidth"
              :editable="false"
              :value="ticket.content"
            ></Editor>
            <v-card-actions>
              <v-chip-group column multiple mandatory dense>
                <v-chip
                  :close="can_handle_tickets"
                  small
                  @click:close="handleRemoveTag(tag)"
                  close-icon="mdi-close"
                  :value="tag.id"
                  outlined
                  v-for="tag in selected_tags"
                  :key="tag.id"
                >
                  <span class="primary--text fw-900">{{ tag.name }}</span>
                </v-chip>
                <v-menu offset-y v-if="can_handle_tickets">
                  <template v-slot:activator="{ on, attrs }">
                    <v-chip v-bind="attrs" v-on="on" outlined small>
                      <v-icon small left>mdi-plus-circle-outline</v-icon>
                      <span class="fw-900">Add Tags</span>
                    </v-chip>
                  </template>
                  <v-list dense>
                    <v-list-item
                      @click="handleAddTag(addtag)"
                      v-for="(addtag, index) in addable_tags"
                      :key="index"
                    >
                      <v-list-item-title>{{ addtag.name }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-chip-group>
            </v-card-actions>
          </v-card>
          <Comment :id="ticket.id" class="mt-5" type="ticket" max-height="auto">
            <template v-slot:label>
              <label class="title font-weight-bold primary--text">
                Comments :
              </label>
            </template>
          </Comment>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import Editor from '@/common/Editor/Editor.vue'
import Comment from '@/common/Comment/Comment.vue'
import { mapActions, mapGetters } from 'vuex'
import _ from 'lodash'
import { support_mixin } from '../support_mixin'

export default {
  inheritAttrs: false,
  components: { Editor, Comment },
  name: 'ViewTicket',
  mixins: [support_mixin],
  props: {
    item: { type: Object, required: true }
  },
  data: () => ({
    assigned: null,
    ticket: null,
    selected_tags: []
  }),
  watch: {
    item: {
      handler: function (val) {
        this.ticket = _.cloneDeep(val)
        if (val) {
          this.selected_tags = _.cloneDeep(val.tags)
        }
      },
      immediate: true,
      deep: true
    }
  },
  computed: {
    ...mapGetters('supports', ['tags', 'ticket_statuses']),
    selected_ids() {
      return this.selected_tags.map((i) => i.id)
    },
    addable_tags() {
      return this.tags.filter((i) => !this.selected_ids.includes(i.id))
    },
    can_statuses() {
      return this.ticket_statuses.filter(
        (i) => this.ticket.status.toLowerCase() != i.toLowerCase()
      )
    }
  },
  methods: {
    ...mapActions('supports', [
      'updateTicketTags',
      'updateTicketStatus',
      'assignTicketTo',
      'deleteTicket'
    ]),
    handleRemoveTag(tag) {
      if (this.ticket.tags.length <= 1) {
        this.appSnackbar(
          'Cannot remove tag, need to have atleast one tag',
          'error'
        )
        return
      }
      this.appConfirmation('Remove this tag?', () => {
        this.updateTicketTags({
          id: this.ticket.id,
          payload: {
            tags: this.selected_tags
              .filter((i) => i.id != tag.id)
              .map((i) => i.id)
          },
          cb: () => {
            let index = this.ticket.tags.findIndex((i) => i.id === tag.id)
            if (~index) {
              this.ticket.tags.splice(index, 1)
            }
            this.appSnackbar('Tags removed!')
          }
        })
      })
    },
    handleAddTag(item) {
      let tags = this.selected_ids
      tags.push(item.id)
      this.updateTicketTags({
        id: this.ticket.id,
        payload: { tags: tags },
        cb: (data) => {
          this.appSnackbar('Tags added!')
        }
      })
    },
    handleChangeStatus(status) {
      this.updateTicketStatus({
        id: this.ticket.id,
        status: status,
        cb: () => {
          this.appSnackbar('Ticket status updated')
          this.ticket.status = status
        }
      })
    },
    handleAssignTo(user) {
      this.appConfirmation(`Assign to  ${user.fullname}?`, () => {
        this.assignTicketTo({
          id: this.ticket.id,
          user_id: user.id,
          cb: () => {
            this.appSnackbar('Ticket successfully assigned')
          }
        })
      })
    },
    handleDeleteTicket() {
      this.appConfirmation(
        `Are you sure you want to delete this ticket? <br>This cannot be undone.`,
        () => {
          this.deleteTicket({
            id: this.ticket.id,
            cb: () => {
              this.$emit('deleted', true)
              this.appSnackbar('Ticket successfully deleted')
            }
          })
        }
      )
    }
  }
}
</script>

<style lang="scss">
.ticket-show {
  .arrow-left:after {
    content: ' ';
    position: absolute;
    left: -10px;
    top: 1px;
    height: 1px;
    border-top: 10px solid transparent;
    border-right: 10px solid $blue;
    border-left: none;
    border-bottom: 10px solid transparent;
  }
}
</style>
