var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',_vm._b({staticClass:"ticket-show"},'v-card',_vm.$attrs,false),[_c('v-card-title',{staticClass:"d-flex align-center justify-space-between"},[_c('strong',{staticClass:"fs-15 primary--text"},[_vm._v(_vm._s(_vm.ticket.slug))]),_c('div',[_c('v-menu',{attrs:{"offset-y":"","bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.can_handle_tickets)?_c('v-btn',_vm._g(_vm._b({staticClass:"text-none mr-1",attrs:{"outlined":"","color":"primary","depressed":""}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm._f("ucwords")(_vm.ticket.assigned[0] ? 'Assigned: ' + _vm.ticket.assigned[0].first_name : 'Assign To'))+" ")]):_vm._e()]}}])},[_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.developers),function(dev,index){return _c('v-list-item',{key:index,on:{"click":function($event){return _vm.handleAssignTo(dev)}}},[_c('v-list-item-avatar',{staticClass:"mr-2",attrs:{"size":"30"}},[_c('v-img',{attrs:{"contain":"","src":dev.image_url,"lazy-src":_vm.settings.loader}})],1),_c('v-list-item-title',{staticClass:"primary--text"},[_vm._v(" "+_vm._s(dev.fullname)+" ")])],1)}),1)],1),_c('v-menu',{attrs:{"offset-y":"","bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"text-none",attrs:{"outlined":"","color":"primary","depressed":"","disabled":!_vm.can_handle_tickets}},'v-btn',attrs,false),on),[_vm._v(" Status: "+_vm._s(_vm._f("ucwords")(_vm.ticket.status))+" ")])]}}])},[_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.can_statuses),function(stat,index){return _c('v-list-item',{key:index,on:{"click":function($event){return _vm.handleChangeStatus(stat)}}},[_c('v-list-item-title',[_vm._v(_vm._s(stat))])],1)}),1)],1),(_vm.can_handle_tickets)?_c('v-tooltip',{attrs:{"color":"primary","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"primary ml-1 light--text rounded-5",attrs:{"icon":""},on:{"click":_vm.handleDeleteTicket}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,false,532970855)},[_c('span',[_vm._v("Delete")])]):_vm._e()],1)]),_c('v-card-text',[_c('v-row',{staticClass:"flex-nowrap",attrs:{"align":"start","justify":"start","dense":""}},[_c('v-tooltip',{attrs:{"color":"primary","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"d-flex align-center justify-center flex-column"},'div',attrs,false),on),[_c('v-avatar',{staticClass:"mr-1",attrs:{"size":"40"}},[_c('v-img',{attrs:{"contain":"","src":_vm.ticket.creator.image_url,"lazy-src":_vm.settings.loader}})],1),_c('strong',{staticClass:"primary--text fw-700"},[_vm._v(" @"+_vm._s(_vm.ticket.creator.username)+" ")])],1)]}}])},[_c('strong',[_vm._v(" @"+_vm._s(_vm.ticket.creator.username)+" ")])]),_c('v-col',[_c('v-card',{staticClass:"arrow-left ml-2 border-1 border-color--primary",attrs:{"flat":""}},[_c('v-card-title',{staticClass:"primary--text fw-500 fs-18"},[_vm._v(" "+_vm._s(_vm._f("ucwords")(_vm.ticket.title))+" ")]),_c('Editor',{staticClass:"fullwidth",attrs:{"editable":false,"value":_vm.ticket.content}}),_c('v-card-actions',[_c('v-chip-group',{attrs:{"column":"","multiple":"","mandatory":"","dense":""}},[_vm._l((_vm.selected_tags),function(tag){return _c('v-chip',{key:tag.id,attrs:{"close":_vm.can_handle_tickets,"small":"","close-icon":"mdi-close","value":tag.id,"outlined":""},on:{"click:close":function($event){return _vm.handleRemoveTag(tag)}}},[_c('span',{staticClass:"primary--text fw-900"},[_vm._v(_vm._s(tag.name))])])}),(_vm.can_handle_tickets)?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({attrs:{"outlined":"","small":""}},'v-chip',attrs,false),on),[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-plus-circle-outline")]),_c('span',{staticClass:"fw-900"},[_vm._v("Add Tags")])],1)]}}],null,false,625700105)},[_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.addable_tags),function(addtag,index){return _c('v-list-item',{key:index,on:{"click":function($event){return _vm.handleAddTag(addtag)}}},[_c('v-list-item-title',[_vm._v(_vm._s(addtag.name))])],1)}),1)],1):_vm._e()],2)],1)],1),_c('Comment',{staticClass:"mt-5",attrs:{"id":_vm.ticket.id,"type":"ticket","max-height":"auto"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('label',{staticClass:"title font-weight-bold primary--text"},[_vm._v(" Comments : ")])]},proxy:true}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }